import { TeamMemberImage } from './Images';
import { PositionAndName } from './Texts';
import { CaptionText } from './Texts';

export const TeamItem1 = ({
  name,
  position,
  caption,
  images,
  member,
  imageSx,
  positionAndNameSx,
  captionSx
}) => (
  <>
    <TeamMemberImage
      member={member}
      {...images[0]}
      sx={{
        gridColumn: [
          '2/13',
          '4/13',
          '10/span 13',
          '13/span 10',
          '13/span 10',
          '13/span 9'
        ],
        gridRow: [4, 4, 3, 3, 3, 3],
        ...imageSx
      }}
    />
    <PositionAndName
      member={member}
      name={name}
      position={position}
      leftText={false}
      sx={{
        alignSelf: 'end',
        gridColumn: [
          '2/13',
          '4/span 7',
          '1/span 9',
          '4/span 9',
          '5/span 8',
          '5/span 8'
        ],
        gridRow: [5, 5, 3, 3, 3, 3],
        ...positionAndNameSx
      }}
    />
    <CaptionText
      data={caption}
      sx={{
        gridColumn: [
          '4/13',
          '6/13',
          '10/span 13',
          '13/span 9',
          '13/span 9',
          '13/span 9'
        ],
        gridRow: [6, 6, 4, 4, 4, 4],
        ...captionSx
      }}
    />
  </>
);

export const TeamItem2 = ({
  name,
  position,
  caption,
  images,
  member,
  imageSx,
  positionAndNameSx,
  captionSx
}) => (
  <>
    <TeamMemberImage
      member={member}
      {...images[0]}
      sx={{
        gridColumn: [
          '1/span 8',
          '2/span 7',
          '1/span 9',
          '4/span 7',
          '4/span 7',
          '3/span 6'
        ],
        gridRow: [7, 7, 5, 5, 5, 5],
        mt: ['47.41%', '47.16%', '37.30%', '32.78%', '28.00%', '32.47%'],
        ...imageSx
      }}
    />
    <PositionAndName
      member={member}
      name={name}
      position={position}
      sx={{
        alignSelf: 'end',
        gridColumn: [
          '1/span 8',
          '2/span 7',
          '10/span 9',
          '11/span 9',
          '11/span 7',
          '9/span 7'
        ],
        gridRow: [8, 8, 5, 5, 5, 5],
        ...positionAndNameSx
      }}
    />
    <CaptionText
      data={caption}
      sx={{
        gridColumn: [
          '3/span 9',
          '4/span 7',
          '1/span 9',
          '4/span 7',
          '4/span 7',
          '3/span 6'
        ],
        gridRow: [9, 9, 6, 6, 6, 6],
        ...captionSx
      }}
    />
  </>
);

export const TeamItem3 = ({
  name,
  position,
  caption,
  images,
  member,
  imageSx,
  positionAndNameSx,
  captionSx
}) => (
  <>
    <TeamMemberImage
      member={member}
      {...images[0]}
      sx={{
        gridColumn: [
          '2/13',
          '3/span 9',
          '4/span 13',
          '7/span 10',
          '7/span 10',
          '7/span 9'
        ],
        gridRow: [10, 10, 7, 7, 7, 7],
        mt: ['34.25%', '36.42%', '25.44%', '22.68%', '19.44%', '21.28%'],
        ...imageSx
      }}
    />
    <PositionAndName
      member={member}
      name={name}
      position={position}
      sx={{
        alignSelf: 'end',
        gridColumn: [
          '2/13',
          '3/span 6',
          '17/25',
          '17/span 7',
          '17/span 7',
          '16/span 7'
        ],
        gridRow: [11, 11, 7, 7, 7, 7],
        ...positionAndNameSx
      }}
    />
    <CaptionText
      data={caption}
      sx={{
        gridColumn: [
          '4/13',
          '5/span 7',
          '4/span 13',
          '7/span 9',
          '7/span 9',
          '7/span 9'
        ],
        gridRow: [12, 12, 8, 8, 8, 8],
        ...captionSx
      }}
    />
  </>
);
