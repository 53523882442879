import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { useTeamPageData, Header, Team } from 'sections/teamPage';
import { SectionSpacer, ParagraphSection } from 'components';
import { useNavOverlayAnimation } from 'context/NavOverlayAnimationContext';

// hooks
import { useCmsTeamPageData } from 'sections/teamPage';

// helper function
import { convertApiHtmlText } from 'sections/helper/convertApiHtmlText';

const TeamPage = () => {
  const { isAnimating } = useNavOverlayAnimation();
  const { seo, header, team } = useTeamPageData();

  const { teamTextBlock, teamMembers } = useCmsTeamPageData();

  if (!isAnimating)
    return (
      <>
        <SEO {...seo} />
        <Header data={header} />
        <SectionSpacer
          spacing={['50.17%', '46.53%', '33.94%', '11.62%', '16.02%', '18.76%']}
        />
        {/* <TextBlock data={textBlock} /> */}
        <ParagraphSection
          text={convertApiHtmlText(
            teamTextBlock.currentLanguageData.description.html
          )}
        />
        <SectionSpacer
          spacing={['75.25%', '46.53%', '33.94%', '23.25%', '24.03%', '31.26%']}
        />
        <Team data={team} cmsData={teamMembers.currentLanguageData} />
        <SectionSpacer
          spacing={['75.25%', '48.08%', '33.94%', '23.25%', '40.04%', '31.26%']}
        />
      </>
    );
  return null;
};

export default injectIntl(TeamPage);
