import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Self Components
import { TeamMemberImage, TeamDogImage } from './components/Images';
import { PositionAndName, CaptionText } from './components/Texts';

// helper function
import { convertApiHtmlText } from 'sections/helper/convertApiHtmlText';
import { TeamItem1, TeamItem2, TeamItem3 } from './components/TeamItems';

export const Team = ({
  data,
  cmsData: {
    ceo_name,
    ceo_position,
    ceo_caption,
    estimation_head_caption,
    estimation_head_name,
    estimation_head_position,
    project_lead_caption,
    project_lead_name,
    project_lead_position,
    sales_caption,
    sales_director_caption,
    sales_director_name,
    sales_director_position,
    sales_name,
    sales_position,
    wood_experts_caption,
    wood_experts_name,
    wood_experts_positon,
    sales_and_marketing_position,
    sales_and_marketing_name,
    sales_and_marketing_caption,
    office_management_position,
    office_management_name,
    office_management_caption,
    accounting_head_position,
    accounting_head_name,
    accounting_head_caption
  }
}) => {
  return (
    // Markup
    <Section id="team-section">
      {/* Currently not needed */}
      {/* <OtherTeamMember
          images={data[0].images}
          name={convertApiHtmlText(sales_name.html)}
          position={sales_position}
          caption={sales_caption}
        /> */}
      <TeamItem1
        images={data[0].images}
        name={convertApiHtmlText(ceo_name.html)}
        position={ceo_position}
        caption={ceo_caption}
        member="stefan"
      />
      <TeamItem2
        images={data[1].images}
        name={convertApiHtmlText(sales_director_name.html)}
        position={sales_director_position}
        caption={sales_director_caption}
        member="steve"
      />
      <TeamItem3
        images={data[2].images}
        name={convertApiHtmlText(estimation_head_name.html)}
        position={estimation_head_position}
        caption={estimation_head_caption}
        member="patrick"
      />
      <TeamItem1
        images={data[3].images}
        name={convertApiHtmlText(sales_and_marketing_name.html)}
        position={sales_and_marketing_position}
        caption={sales_and_marketing_caption}
        member="christine_heil"
        imageSx={{
          gridRow: [13, 13, 9, 9, 9, 9],
          mt: ['34.25%', '36.42%', '27.64%', '22.68%', '19.44%', '21.28%']
        }}
        positionAndNameSx={{
          gridRow: [14, 14, 9, 9, 9, 9]
        }}
        captionSx={{
          gridRow: [15, 15, 10, 10, 10, 10]
        }}
      />
      <TeamItem2
        images={data[4].images}
        name={convertApiHtmlText(office_management_name.html)}
        position={office_management_position}
        caption={office_management_caption}
        member="melanie_lorenz"
        imageSx={{
          gridRow: [16, 16, 11, 11, 11, 11]
        }}
        positionAndNameSx={{
          gridRow: [17, 17, 11, 11, 11, 11]
        }}
        captionSx={{
          gridRow: [18, 18, 12, 12, 12, 12]
        }}
      />
      <TeamItem3
        images={data[5].images}
        name={convertApiHtmlText(accounting_head_name.html)}
        position={accounting_head_position}
        caption={accounting_head_caption}
        member="hanane_bouzid"
        imageSx={{
          gridRow: [19, 19, 13, 13, 13, 13]
        }}
        positionAndNameSx={{
          gridRow: [20, 20, 13, 13, 13, 13]
        }}
        captionSx={{
          gridRow: [21, 21, 14, 14, 14, 14]
        }}
      />
      <Luca
        images={data[6].images}
        name={convertApiHtmlText(project_lead_name.html)}
        position={project_lead_position}
        caption={project_lead_caption}
        rowOffset={9}
      />
      <CaraMel
        images={data[7].images}
        name={convertApiHtmlText(wood_experts_name.html)}
        position={wood_experts_positon}
        caption={wood_experts_caption}
        rowOffset={9}
      />
    </Section>
  );
};

// Elements

// {/* Currently not needed - becomes relevant if they have a new team member again */}
// const OtherTeamMember = ({ name, position, caption, images }) => (
//   <>
//     <TeamMemberImage
//       member="ramona"
//       {...images[0]}
//       sx={{
//         gridColumn: [
//           '1/span 10',
//           '1/span 8',
//           '1/span 11',
//           '1/span 9',
//           '1/span 9',
//           '1/span 8'
//         ],
//         gridRow: 1
//       }}
//     />
//     <PositionAndName
//       member="ramona"
//       name={name}
//       position={position}
//       sx={{
//         alignSelf: 'end',
//         gridColumn: [
//           '1/13',
//           '1/span 10',
//           '12/25',
//           '10/span 11',
//           '10/span 11',
//           '9/span 11'
//         ],
//         gridRow: [2, 2, 1, 1, 1, 1]
//       }}
//     />
//     <CaptionText
//       data={caption}
//       sx={{
//         gridColumn: [
//           '3/span 9',
//           '3/span 7',
//           '1/span 11',
//           '1/span 9',
//           '1/span 9',
//           '1/span 8'
//         ],
//         gridRow: [3, 3, 2, 2, 2, 2]
//       }}
//     />
//   </>
// );

const Luca = ({ name, position, caption, images }) => (
  <>
    <TeamMemberImage
      member="luca"
      {...images[0]}
      sx={{
        gridColumn: [
          '1/span 11',
          '1/span 9',
          '2/span 13',
          '1/span 10',
          '1/span 10',
          '2/span 9'
        ],
        gridRow: [22, 22, 16, 16, 16, 16],
        mt: ['34.25%', '36.42%', '6.36%', '5.67%', '5.83%', '4.47%']
      }}
    />
    <PositionAndName
      member="luca"
      name={name}
      position={position}
      sx={{
        gridColumn: [
          '1/span 11',
          '1/span 7',
          '2/span 13',
          '1/span 10',
          '1/span 10',
          '2/span 9'
        ],
        gridRow: [23, 23, 15, 15, 15, 15],
        mt: [null, null, '25.44%', '22.68%', '19.44%', '21.28%']
      }}
    />
    <CaptionText
      data={caption}
      sx={{
        gridColumn: [
          '3/span 9',
          '3/span 7',
          '2/span 13',
          '1/span 10',
          '1/span 10',
          '2/span 9'
        ],
        gridRow: [24, 24, 17, 17, 17, 17]
      }}
    />
  </>
);

const CaraMel = ({ name, position, caption, images }) => (
  <>
    <TeamDogImage
      member="cara"
      image={images[0]}
      sx={{
        gridColumn: [
          '2/span 5',
          '4/span 4',
          '6/span 6',
          '11/span 5',
          '12/span 4',
          '12/span 4'
        ],
        gridRow: [25, 25, 18, 16, 16, 16]
      }}
    />
    <TeamDogImage
      member="mel"
      image={images[1]}
      sx={{
        gridColumn: [
          '7/span 5',
          '8/span 4',
          '12/span 6',
          '15/span 5',
          '16/span 4',
          '16/span 4'
        ],
        gridRow: [25, 25, 18, 16, 16, 16],
        justifySelf: 'end'
      }}
    />
    <PositionAndName
      member="caramel"
      name={name}
      position={position}
      sx={{
        alignSelf: 'end',
        gridColumn: [
          '2/span 7',
          '4/span 5',
          '18/25',
          '20/25',
          '20/25',
          '20/25'
        ],
        gridRow: [26, 26, 18, 16, 16, 16]
      }}
    />
    <CaptionText
      data={caption}
      sx={{
        gridColumn: [
          '4/13',
          '6/13',
          '6/span 12',
          '11/span 9',
          '12/span 8',
          '12/span 8'
        ],
        gridRow: [27, 27, 19, 17, 17, 17]
      }}
    />
  </>
);
