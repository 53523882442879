import React from 'react';

// External Components
import { Section, Heading } from '@thepuzzlers/pieces';

// Local Components
import { ImageWithClipPath } from 'components';

export const Header = ({ data: { headline, images } }) => {
  const sectionRef = React.useRef(null);

  return (
    // Markup
    <Section id="team-header" ref={sectionRef}>
      {/* <HeadlineWrapper data={headline} /> */}
      <ImageOne {...images[0]} />
      <Headline data={headline} />
      <ImageTwo {...images[1]} />
      <ImageThree {...images[2]} />
    </Section>
  );
};

const Headline = ({ data }) => (
  <Heading
    as="h1"
    type="header-xl"
    variant="bold"
    sx={{
      textTransform: 'uppercase',
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
      mt: ['10%', '10%', '9%', '9%', '2%', '2%'],
      textAlign: 'center',
      gridRow: [null, null, null, 1, 'auto']
    }}>
    {data}
  </Heading>
);

const ImageOne = ({ src, alt }) => (
  <ImageWithClipPath
    className="team-header__image-one"
    src={src}
    alt={alt}
    sx={{
      borderRadius: [2, 2, 4, 4, 4, 4],
      gridColumn: [
        '5/13',
        '6/13',
        '12/span 10',
        '15/span 8',
        '15/span 7',
        '12/span 6'
      ],
      // gridRow: 1,
      overflow: 'hidden',
      mt: ['21.92%', '16.97%', '8.10%', '10.98%', '9.28%', '2.34%'],
      aspectRatio: '1.5 / 1'
    }}
  />
);

const ImageTwo = ({ src, alt }) => (
  <ImageWithClipPath
    className="team-header__image-two"
    src={src}
    alt={alt}
    // Animation value
    customDelay={0.5}
    sx={{
      alignSelf: 'start',
      borderRadius: [2, 2, 4, 4, 4, 4],
      gridColumn: [
        '1/span 8',
        '1/span 7',
        '1/span 11',
        '1/span 9',
        '3/span 9',
        '3/span 8'
      ],
      // gridRow: 2,
      overflow: 'hidden',
      mt: ['18.55%', '17.34%', '28.14%', 0, '13.02%', '14%'],
      zIndex: 2,
      aspectRatio: '1 / 1'
    }}
  />
);

const ImageThree = ({ src, alt }) => (
  <ImageWithClipPath
    className="team-header__image-three"
    src={src}
    alt={alt}
    // Animation value
    customDelay={1}
    sx={{
      borderRadius: [2, 2, 4, 4, 4, 4],
      gridColumn: ['4/13', '5/13', '14/25', '15/span 9', '16/25', '15/span 9'],
      // gridRow: [3, 3, 2, 2, 2, 2],
      overflow: 'hidden',
      mt: ['26.55%', '18.19%', '98.32%', '42.82%', '65.01%', '62.75%'],
      aspectRatio: '1.5 / 1'
    }}
  />
);
