import { graphql, useStaticQuery } from 'gatsby';

// helper
import { useGetCurrentLanguageCmsData } from 'sections/helper';

export const useCmsTeamPageData = () => {
  const { getCurrentLanguageData } = useGetCurrentLanguageCmsData();

  const { allRestApiPublicPagesTeamEntries } = useStaticQuery(graphql`
    query {
      allRestApiPublicPagesTeamEntries {
        nodes {
          data {
            key
            name
            contentTypes {
              name
              key
              entries {
                id
                entry {
                  ceo_name {
                    html
                  }
                  ceo_position
                  description {
                    html
                  }
                  estimation_head_caption
                  estimation_head_name {
                    html
                  }
                  estimation_head_position
                  project_lead_caption
                  project_lead_name {
                    html
                  }
                  sales_caption
                  project_lead_position
                  sales_director_caption
                  sales_director_name {
                    html
                  }
                  sales_director_position
                  sales_name {
                    html
                  }
                  sales_position
                  ceo_caption
                  wood_experts_caption
                  wood_experts_name {
                    html
                  }
                  wood_experts_positon
                  sales_and_marketing_position
                  sales_and_marketing_name {
                    html
                  }
                  sales_and_marketing_caption
                  office_management_position
                  office_management_name {
                    html
                  }
                  office_management_caption
                  accounting_head_position
                  accounting_head_name {
                    html
                  }
                  accounting_head_caption
                }
                translations {
                  en {
                    ceo_name {
                      html
                    }
                    ceo_position
                    description {
                      html
                    }
                    estimation_head_caption
                    estimation_head_name {
                      html
                    }
                    estimation_head_position
                    project_lead_caption
                    project_lead_name {
                      html
                    }
                    project_lead_position
                    sales_caption
                    sales_director_caption
                    sales_director_name {
                      html
                    }
                    sales_director_position
                    sales_name {
                      html
                    }
                    sales_position
                    ceo_caption
                    wood_experts_caption
                    wood_experts_name {
                      html
                    }
                    wood_experts_positon
                    sales_and_marketing_position
                    sales_and_marketing_name {
                      html
                    }
                    sales_and_marketing_caption
                    office_management_position
                    office_management_name {
                      html
                    }
                    office_management_caption
                    accounting_head_position
                    accounting_head_name {
                      html
                    }
                    accounting_head_caption
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const teamPageSectionData =
    allRestApiPublicPagesTeamEntries.nodes[0].data.contentTypes;

  const currentLanguageData = teamPageSectionData.map((sectionData) => {
    return {
      ...sectionData,
      currentLanguageData: getCurrentLanguageData(sectionData.entries[0])
    };
  });

  return {
    teamTextBlock: currentLanguageData[0],
    teamMembers: currentLanguageData[1]
  };
};
