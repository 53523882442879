import React from 'react';

// External Components
import { Box, Heading, Paragraph } from '@thepuzzlers/pieces';

export const PositionAndName = ({
  member,
  name,
  position,
  leftText = true,
  sx
}) => (
  <Box
    className={`team__position-and-name__${member}`}
    sx={{
      mt: ['0.5em', '0.5em', 0, 0, 0, 0],
      textAlign: leftText
        ? 'left'
        : ['left', 'left', 'right', 'right', 'right', 'right'],
      ...sx
    }}>
    <Paragraph
      type="p-300-150"
      variant="bold"
      sx={{ textTransform: 'uppercase' }}>
      {position}
    </Paragraph>
    <Heading
      as="h3"
      type="h3-700-110"
      variant="bold"
      dangerouslySetInnerHTML={{
        __html: name
      }}
      sx={{
        mt: [0, 0, '0.11em', '0.12em', '0.09em', '0.1em'],
        textTransform: 'uppercase'
      }}
    />
  </Box>
);

export const CaptionText = ({ data, sx }) => (
  <Paragraph
    className="team__caption-text"
    type="p-300-150"
    variant="bold"
    sx={{
      mt: ['1.24em', '1.23em', '0.62em', '0.62em', '0.62em', '1.23em'],
      textTransform: 'uppercase',
      ...sx
    }}>
    {data}
  </Paragraph>
);
