import React from 'react';

// Local Components
import { AnimatedImage } from 'components';

export const TeamMemberImage = ({ member, src, alt, sx }) => (
  <AnimatedImage
    className={`team__${member}`}
    src={src}
    alt={alt}
    sx={{
      borderRadius: ['2px', '2px', '4px', '2px', '2px', '2px'],
      overflow: 'hidden',
      ...sx
    }}
  />
);

export const TeamDogImage = ({ member, image, sx }) => (
  <TeamMemberImage
    member={member}
    sx={{
      alignSelf: 'end',
      mt: ['76.96%', '84.58%', '57.34%', 0, 0, 0],
      width: ['100%', '100%', '100%', '90%', '100%', '100%'],
      ...sx
    }}
    {...image}
  />
);
